import React from 'react';
import PropTypes from 'prop-types';
// import { domToReact } from 'html-react-parser';
import RichTextElement from '~/2-components/RichTextElement/RichTextElement';
import LinkedItem from '~3-data-components/LinkedItem';
import InlineLink from '~3-data-components/InlineLink';
import InlineAsset from '~3-data-components/InlineAsset';
import { RichTextValidation } from '~2-components/Utils/RichTextValidation';
// import { KenticoRichTextElement } from '~/@types/kentico';
// import Table from '~/1-core/tables/Table';

/*
 * For context check out https://rshackleton.co.uk/articles/rendering-kentico-cloud-linked-content-items-with-react-components-in-gatsby
 *
 */
const RichText = (props) => {
    const { content, pageInfo } = props;

    if (!RichTextValidation(content)) return null;

    return (
        <RichTextElement
            value={content.value}
            images={content.images}
            links={content.links}
            linkedItems={content.modular_content}
            resolveImage={image => (
                <InlineAsset
                    description={image.description}
                    id={image.image_id}
                    image={image.fluid}
                    width={image.width}
                    height={image.height}
                />
            )}
            resolveLink={(link, domNode) => {
                const childrenData = domNode.children[0].data;

                return (
                    <InlineLink link_id={link.link_id} type={link.type} url_slug={link.url_slug}>
                        {childrenData}
                    </InlineLink>
                );
            }}
            resolveLinkToAsset={domNode => {
                if (domNode.attribs.href.includes('.pdf')) {
                    domNode.attribs.target = '_blank';
                    return domNode;
                }

                return domNode;
            }}
            resolveLinkedItem={(linkedItem => <LinkedItem system={linkedItem.system} pageInfo={pageInfo}/>)}
            // resolveTable={domNode => <Table>{domToReact(domNode.children)}</Table>}
        />
    );
};

export default RichText;

RichText.propTypes = {
    content: PropTypes.shape({
        value: PropTypes.any.isRequired,
        images: PropTypes.any,
        links: PropTypes.any,
        modular_content: PropTypes.any
    }).isRequired,
    pageInfo: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
    }),
};
