import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Icon from '~2-components/IconInline/IconInline';

const TestimonialItem = (props) => {
    let content, name;

    if (props.system) {
        // data comes from CMS

        const testimonialID = props.system.id;

        const allTestimonialItems = useStaticQuery(
            graphql`
                query allTestimonial {
                    allKontentItemTestimonial {
                        nodes {
                            system {
                                id
                            }
                            elements {
                                name {
                                    value
                                }
                                testimonial {
                                    value
                                }
                            }
                        }
                    }
                }
        `);

        const match = useMemo(() => (
            allTestimonialItems.allKontentItemTestimonial.nodes.find((n) => testimonialID === n.system.id)
        ), [ allTestimonialItems, testimonialID]);

        content = match.elements.testimonial.value;
        name = match.elements.name.value;
    } else {
        content = props.content;
        name = props.name;
    }

    return (
        <div className={`testimonial-item`}>
            <div className="testimonial-icon">
                <Icon icon="quote-marks" />
            </div>
            <div className="testimonial-content">
                <p>{content}</p>
                {name && <span>- {name}</span>}
            </div>
        </div>
    );
};

export default TestimonialItem;

TestimonialItem.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    content: PropTypes.string,
    name: PropTypes.string,
};
