import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TestimonialItem from '~2-components/TestimonialItem/TestimonialItem';
import SliderContainer from '~2-components/Slider/SliderContainer';
import { useStaticQuery, graphql } from 'gatsby';

const TestimonialCarousel = (props) => {
    let items;

    if (props.system) {
        //data comes from CMS

        const carouselID = props.system.id;

        const allTestimonialCarousels = useStaticQuery(
            graphql`
                query allTestimonialCarousels {
                    allKontentItemTestimonialPanel {
                        nodes {
                            system {
                                id
                                type
                            }
                            elements {
                                testimonials {
                                    value {
                                        system {
                                            type
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
        `);

        const match = useMemo(() => (
            allTestimonialCarousels.allKontentItemTestimonialPanel.nodes.find((n) => carouselID === n.system.id)
        ), [ allTestimonialCarousels, carouselID]);

        items = match.elements.testimonials.value;
    } else {
        items = props.testimonials;
    }

    return (
        <div className="testimonial-carousel">
            <div className="container">
                <SliderContainer>
                    {items.map((item, i) => {
                        return (
                            <TestimonialItem
                                key={i}
                                {...item}
                            />
                        );
                    })}
                </SliderContainer>
            </div>
        </div>
    );
};

export default TestimonialCarousel;

TestimonialCarousel.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    items: PropTypes.array,
};
