/**
 * CODE IS COPIED FROM: https://github.com/Kentico/kontent-gatsby-packages/blob/master/packages/gatsby-kontent-components/src/rich-text-element/index.tsx
 * Since from source itself doesn't support <table> and can't add some functionality on certain elements,
 * We modify it, maybe in the future if they update that we can reuse it
*/
/* eslint-disable @typescript-eslint/no-explicit-any */
import parseHTML, { DomElement, domToReact } from 'html-react-parser';

const IMAGE_ID_ATTRIBUTE_IDENTIFIER = 'data-image-id';
const LINKED_ITEM_ID_ATTRIBUTE_IDENTIFIER = 'data-item-id';
const ASSET_ID_ATTRIBUTE_IDENTIFIER = 'data-asset-id';

interface Props {
  value: string;
  linkedItems?: any[];
  resolveLinkedItem?: Function;
  images?: any[];
  resolveImage?: Function;
  links?: any[];
  resolveLink?: Function;
  resolveLinkToAsset?: Function;
  resolveTable?: Function;
}

const isLinkedItem = (domNode: DomElement): boolean => domNode.name === 'object' && domNode.attribs?.type === 'application/kenticocloud';

const isImage = (domNode: DomElement): boolean => domNode.name === 'figure' && typeof domNode.attribs?.[IMAGE_ID_ATTRIBUTE_IDENTIFIER] !== 'undefined';

const isLink = (domNode: DomElement): boolean => domNode.name === 'a' && typeof domNode.attribs?.[LINKED_ITEM_ID_ATTRIBUTE_IDENTIFIER] !== 'undefined';

const isLinkToAsset = (domNode: DomElement) => domNode.name === 'a' && typeof domNode.attribs?.[ASSET_ID_ATTRIBUTE_IDENTIFIER] !== 'undefined';

const isTable = (domNode: DomElement) => domNode.name === 'table' && domNode.children && domNode.children.length > 0;

const replaceNode = (
    domNode: DomElement,
    linkedItems: any[] | undefined,
    resolveLinkedItem: Function | undefined,
    images: any[] | undefined,
    resolveImage: Function | undefined,
    links: any[] | undefined,
    resolveLink: Function | undefined,
    resolveLinkToAsset: Function | undefined,
    resolveTable: Function | undefined,
// eslint-disable-next-line consistent-return
): void => {
    if (resolveLinkedItem && linkedItems) {
        if (isLinkedItem(domNode)) {
            const codeName = domNode.attribs?.['data-codename'];
            const linkedItem = linkedItems.find(item => item.system.codename === codeName);
            return resolveLinkedItem(linkedItem);
        }
    }

    if (resolveImage && images) {
        if (isImage(domNode)) {
            const imageId = domNode.attribs?.[IMAGE_ID_ATTRIBUTE_IDENTIFIER];
            const image = images.find(imageItem => imageItem.image_id === imageId);
            return resolveImage(image);
        }
    }

    if (resolveLink && links) {
        if (isLink(domNode)) {
            const linkId = domNode.attribs?.[LINKED_ITEM_ID_ATTRIBUTE_IDENTIFIER];
            const link = links.find(linkItem => linkItem.link_id === linkId);
            return resolveLink(link, domNode);
        }
    }

    if (resolveLinkToAsset) {
        if (isLinkToAsset(domNode)) {
            return resolveLinkToAsset(domNode);
        }
    }

    if (resolveTable) {
        if (isTable(domNode)) {
            return resolveTable(domNode);
        }
    }
};

const RichTextElement = ({
    value, linkedItems, resolveLinkedItem, images, resolveImage, links, resolveLink, resolveLinkToAsset, resolveTable,
}: Props): ReturnType<typeof domToReact> => (
    parseHTML(value, {
        // eslint-disable-next-line max-len
        replace: (domNode: DomElement) => replaceNode(domNode, linkedItems, resolveLinkedItem, images, resolveImage, links, resolveLink, resolveLinkToAsset, resolveTable),
    })
);

export default RichTextElement;
