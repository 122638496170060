import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useStaticQuery, graphql } from 'gatsby';
import formatURL from "~utils/formatURL";

const AllNewsArtiles = (props) => {
    let articles = [];

    if (props.system) {
        const allNewsArticles = useStaticQuery(
            graphql`
                query allNewsArticles {
                    allKontentItemNewsArticle {
                        nodes {
                            system {
                                id
                            }
                            elements {
                                title {
                                    value
                                }
                                date {
                                    value
                                }
                                page_url {
                                    value
                                }
                            }
                        }
                    }
                }
        `);

        articles = allNewsArticles.nodes;
    } else {
        articles = props.articles;
    }
    return (
        <div className="page-section all-news-articles-list">
            <ul>
                {articles.map(item => {
                const {title, date, page_url} = item;

                return (
                    <li className="flat-news-row" key={uuidv4()}>
                        <div className="flat-news-date">{date}</div>
                        &nbsp;
                        <a href={formatURL(page_url)}>{title}</a>
                    </li>
                );
            })}
            </ul>
        </div>
    );
};

export default AllNewsArtiles;

AllNewsArtiles.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    articles: PropTypes.array,
};
