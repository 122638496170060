/* eslint-disable @typescript-eslint/camelcase */
import { useStaticQuery, graphql } from 'gatsby';
import { processFilterOptionData } from './ItemFilterOptionsData';
import { ArticleNode, ProfileNode } from '~/@types/pages';
import {
    FILTER_OPTION_TYPE_LINKED_ITEMS,
} from '../Constants';

interface allKontentItemNewsArticle {
    nodes: ArticleNode[];
}

interface ArticleData {
    allKontentItemNewsArticle: allKontentItemNewsArticle;
}

const ArticlesData = () => {
    const data: ArticleData = useStaticQuery(graphql`
        {
            allKontentItemNewsArticle {
                nodes {
                  id
                  elements {
                    title {
                      value
                    }
                    page_url {
                      value
                    }
                    date {
                      timestamp: value
                      value(formatString: "DD MMMM YYYY")
                    }
                    thumbnail_image {
                      value {
                        description
                        name
                        fluid(maxWidth: 733) {
                            ...KontentAssetFluid_withWebp
                        }
                      }
                    }
                    author {
                      value {
                        ... on kontent_item_profile {
                          elements {
                            first_name {
                              value
                            }
                            last_name {
                              value
                            }
                          }
                          system {
                            type
                            id
                          }
                        }
                      }
                    }
                    practice_areas {
                      value {
                        ... on kontent_item_general_content {
                          elements {
                                  title {
                                      value
                                  }
                              }
                              system {
                                  type
                                  id
                              }
                          }
                      }
                  }
                  }
                }
            }
        }
    `);

    const processAuthorData = (linkedItems: ProfileNode[]) => {
        const {
            elements: {
                first_name, last_name, position
            },
        } = linkedItems[0];
        return {
            name: `${first_name.value} ${last_name && last_name.value}`,
            position: position.value,
        };
    };

    const convertedData = data.allKontentItemNewsArticle.nodes.map(node => {
        const {
            id,
            elements: {
                page_url,
                title,
                introduction,
                date,
                author,
                thumbnail_image,
                practice_areas,
            },
        } = node;

        const calcDate = date && date.value ? date.value : '01 January 1900';

        const practiceAreaCodeName = practice_areas.value.length > 0 && practice_areas.value[0].system
            ? practice_areas.value[0].system.type
            : '';


        const authorCodeName = author.value.length > 0 && author.value[0].system
            ? author.value[0].system.type
            : '';

        const introductionValue = introduction && introduction.value ? introduction.value : '';

        return {
            guid: id,
            url: page_url.value,
            title: title.value,
            introduction: introductionValue,
            image: thumbnail_image.value,
            timestamp: date.timestamp,
            date: calcDate,
            filterOptions: processFilterOptionData([
                {
                    data: author,
                    type: FILTER_OPTION_TYPE_LINKED_ITEMS,
                    codename: authorCodeName,
                },
                {
                    data: practice_areas,
                    type: FILTER_OPTION_TYPE_LINKED_ITEMS,
                    codename: practiceAreaCodeName,
                },
            ]),
        };
    });

    return convertedData;
};

export default ArticlesData;
