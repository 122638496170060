import React from 'react';
import PropTypes from 'prop-types';

export const ColumnOne = ({ children }) => (<>{children}</>);
export const ColumnTwo = ({ children }) => (<>{children}</>);

const AwardsAndAccolades = ( props ) => {
    const {
        title,
        cmsImage,
        image,
        children,
    } = props;

    const columnOne = children.filter(child => child.type === ColumnOne);
    const columnTwo = children.filter(child => child.type === ColumnTwo);

    return (
        <div className="awards-and-accolades">
            <div className="background-image">
                {/* Handles Gatsby Images */}
                {cmsImage &&
                    cmsImage
                }
                {image &&
                    <img src={image.mobileImage} srcSet={`${image.mobileImage} 768w, ${image.tabletImage} 1024w, ${image.desktopImage} 1600w`} alt={image.description} />
                }
            </div>

            <div className="awards-image-overlay"></div>

            <div className="container">
                <h2>{title}</h2>
                <div className="awards-container">
                    <div className="award-group">
                        {columnOne}
                    </div>
                    <div className="award-group">
                        {columnTwo}
                    </div>
                </div>
            </div>
        </div>
    );
};

AwardsAndAccolades.propTypes = {
    title: PropTypes.string,
    cmsImage: PropTypes.node,
    image: PropTypes.object,
    children: PropTypes.node,
};

ColumnOne.propTypes = {
    children: PropTypes.node,
};

ColumnTwo.propTypes = {
    children: PropTypes.node,
};

export default AwardsAndAccolades;
