import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import RichText from '~3-data-components/RichText';
import PageSection from '~2-components/PageSection/PageSection';

const PageSectionKontent = (props) => {
    const { system } = props;
    const { id: pageSectionID } = system;

    const allPageSections = useStaticQuery(graphql`
        query allPageSections {
            allKontentItemPageSection {
                nodes {
                    elements {
                        text_layout {
                            value {
                                codename
                            }
                        }
                        background {
                            value {
                                codename
                            }
                        }
                        left_aligned_single_column_text {
                            value {
                                codename
                            }
                        }
                        content {
                            images {
                                url
                                image_id
                                description
                            }
                            modular_content {
                                system {
                                    id
                                    type
                                    codename
                                }
                            }
                            value
                            links {
                                url_slug
                                type
                                link_id
                                codename
                            }
                        }
                        sidebar_content {
                            images {
                                url
                                image_id
                                description
                            }
                            modular_content {
                                system {
                                    id
                                    type
                                    codename
                                }
                            }
                            value
                            links {
                                url_slug
                                type
                                link_id
                                codename
                            }
                        }
                    }
                    system {
                        id
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        allPageSections.allKontentItemPageSection.nodes.find((n) => pageSectionID === n.system.id)
    ), [ allPageSections, pageSectionID]);

    const {
        background,
        text_layout,
        content,
        sidebar_content,
        left_aligned_single_column_text
    } = match.elements;

    return (
        <PageSection
            backgroundTheme={background.value.length > 0 ? background.value[0].codename : ''}
            textLayout={text_layout.value.length > 0 ? text_layout.value[0].codename : ''}
            leftAlignedContent={left_aligned_single_column_text.value.length > 0 ? left_aligned_single_column_text.value[0].codename : ''}
            leftColContent={<RichText
                content={content}
                images={content.images}
                links={content.links}
                linkedItems={content.modular_content}
            />}
            rightColContent={<RichText
                content={sidebar_content}
                images={sidebar_content.images}
                links={sidebar_content.links}
                linkedItems={sidebar_content.modular_content}
            />}
        />
    );
};

export default PageSectionKontent;

PageSectionKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired
};
