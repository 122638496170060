import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import RichText from '~3-data-components/RichText';
import AwardsAndAccolades, { ColumnOne, ColumnTwo } from '~2-components/AwardsAndAccolades/AwardsAndAccolades';
import { RichTextValidation } from '~2-components/Utils/RichTextValidation';

const AwardsAndAccoladesKontent = ( props ) => {
    const { system } = props;
    const { id: awardsAndAccoladesId } = system;

    const allAwardsAndAccolades = useStaticQuery(graphql`
        query allAwardContainers {
            allKontentItemAwardsAndAccolades {
                nodes {
                    system {
                        id
                    }
                    elements {
                        title {
                            value
                        }
                        image {
                            value {
                                description
                                name
                                fluid(maxWidth: 1200) {
                                    ...KontentAssetFluid_withWebp
                                }
                            }
                        }
                        column_1 {
                            value
                            modular_content {
                                system {
                                    id
                                    type
                                    codename
                                }
                            }
                        }
                        column_2 {
                            value
                            modular_content {
                                system {
                                    id
                                    type
                                    codename
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        allAwardsAndAccolades.allKontentItemAwardsAndAccolades.nodes.find((n) => awardsAndAccoladesId === n.system.id)
    ), [ allAwardsAndAccolades, awardsAndAccoladesId]);
    const {
        title,
        column_1,
        column_2,
        image,
    } = match.elements;
    const cmsImage = (image && image.value.length > 0 && image.value[0].fluid) ? <GatsbyImage
        key={image.value[0].name}
        alt={image.value[0].description}
        fluid={image.value[0].fluid}
    /> : '';
    return (
        <AwardsAndAccolades
            title = {title.value}
            cmsImage = {cmsImage}
        >
            <ColumnOne>
                {RichTextValidation(column_1) &&
                    <RichText
                        content={column_1}
                        images={column_1.images}
                        links={column_1.links}
                        linkedItems={column_1.modular_content}
                    />
                }
            </ColumnOne>
            <ColumnTwo>
                {RichTextValidation(column_2) &&
                    <RichText
                        content={column_2}
                        images={column_2.images}
                        links={column_2.links}
                        linkedItems={column_2.modular_content}
                    />
                }
            </ColumnTwo>
        </AwardsAndAccolades>
    );
};

AwardsAndAccoladesKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
};

export default AwardsAndAccoladesKontent;
