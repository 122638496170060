import { useStaticQuery, graphql } from 'gatsby';
import { processFilterOptionData } from './ItemFilterOptionsData';
import { ProfileNode } from '~/@types/pages';
import {
    FILTER_OPTION_TYPE_LINKED_ITEMS,
} from '../Constants';

interface AllKontentItemProfile {
    nodes: ProfileNode[];
}

interface ProfileData {
    allKontentItemProfile: AllKontentItemProfile;
}

const ProfilesData = () => {
    const data: ProfileData = useStaticQuery(graphql`
        {
          allKontentItemProfile {
            nodes {
                system {
                    id
                }
                elements {
                    thumbnail_image {
                        value {
                            description
                            name
                            fluid(maxWidth: 152) {
                                ...KontentAssetFluid_withWebp
                            }
                        }
                    }
                    page_url {
                        value
                    }
                    law_institute_specialist_accreditation {
                        value {
                            codename
                        }
                    }
                    last_name {
                        value
                    }
                    first_name {
                        value
                    }
                    position {
                        value
                    }
                    phone {
                        value
                    }
                    email {
                        value
                    }
                    linkedin {
                        value
                    }
                    hide_from_au {
                      value {
                        codename
                      }
                    }
                    location {
                        value {
                            ... on kontent_item_location {
                                elements {
                                    location_city {
                                        value
                                    }
                                }
                                system {
                                    type
                                    id
                                }
                            }
                        }
                    }
                    practice_areas {
                        value {
                            ... on kontent_item_general_content {
                                elements {
                                    title {
                                        value
                                    }
                                }
                                system {
                                    type
                                    id
                                }
                            }
                        }
                    }
                    sort_position {
                        value {
                            ... on kontent_item_position {
                                id
                                elements {
                                    sort_order {
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
            }
          }
        }
    `);

    const filteredData = data.allKontentItemProfile.nodes.filter(node => {
        const hide_au_value = node.elements.hide_from_au.value;
        if(hide_au_value.length === 0){ return true; }
        hide_au_value.filter(val =>{ return (val.codename === "australia"); });
    });

    const convertedData = filteredData.map(node => {
        const {
            system:{id},
            elements: {
            first_name,
            last_name,
            position,
            linkedin,
            page_url,
            thumbnail_image,
            law_institute_specialist_accreditation,
            phone,
            email,
            location,
            practice_areas,
            sort_position
            },
        } = node;
        const imageAttr = thumbnail_image.value[0];
        const locationCodeName = location.value.length > 0 && location.value[0].system
            ? location.value[0].system.type
            : '';
        const practiceAreaCodeName = practice_areas.value.length > 0 && practice_areas.value[0].system
            ? practice_areas.value[0].system.type
            : '';
        const sortPosition = sort_position.value.length > 0 && sort_position.value[0].elements
        ? sort_position.value[0].elements.sort_order.value
        : 100;
        return {
            guid: id,
            accredited: law_institute_specialist_accreditation.value.length > 0 ? law_institute_specialist_accreditation.value[0].codename : '',
            firstName: first_name.value,
            lastName: last_name.value,
            title: `${first_name.value} ${last_name.value}`,
            introduction: position.value,
            position: position.value,
            phone: phone.value,
            email: email.value,
            linkedIn: linkedin.value,
            urlSlug: page_url.value,
            id: imageAttr && imageAttr.name,
            description: imageAttr && imageAttr.description,
            image: thumbnail_image.value,
            sort_position: sortPosition,
            filterOptions: processFilterOptionData([
                {
                    data: location,
                    type: FILTER_OPTION_TYPE_LINKED_ITEMS,
                    codename: locationCodeName,
                },
                {
                    data: practice_areas,
                    type: FILTER_OPTION_TYPE_LINKED_ITEMS,
                    codename: practiceAreaCodeName,
                },
            ]),
        };
    });
    return convertedData;
};

export default ProfilesData;
