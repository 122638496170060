import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import NewsTile from '~2-components/NewsTile/NewsTile';
import GatsbyImage from 'gatsby-image';

const NewsTileKontent = (props) => {
    const { system } = props;
    const { id: tileId } = system;

    const tileData = useStaticQuery(graphql`
        query NewsTile {
            allKontentItemNewsArticle {
                nodes {
                    system {
                        id
                    }
                    elements {
                        date {
                            value(formatString: "D MMM YYYY")
                        }
                        title {
                            value
                        }
                        thumbnail_image {
                            value {
                                description
                                name
                                url
                            }
                        }
                        page_url {
                            value
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        tileData.allKontentItemNewsArticle.nodes.find((n) => tileId === n.system.id)
    ), [ tileData, tileId]);

    const {
        page_url,
        date,
        title,
        thumbnail_image,
    } = match.elements;
    const imgUrl = ((thumbnail_image && thumbnail_image.value.length > 0 ) && thumbnail_image.value[0].url) ? thumbnail_image.value[0].url : '';
    return (
        <NewsTile
            url={`/${page_url.value}`}
            cmsImage={(thumbnail_image.value.length >= 1 && thumbnail_image.value[0].fluid) &&
                <GatsbyImage
                    key={thumbnail_image.value[0].name}
                    alt={thumbnail_image.value[0].description}
                    fluid={thumbnail_image.value[0].fluid}
                    style={{position: "absolute"}}
                />
            }
            imgUrl={imgUrl}
            imgAlt={title.value}
            date={date.value}
            title={title.value}
        />
    );
};

export default NewsTileKontent;

NewsTileKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
};
