import ArticlesData from './ArticlesData';
import PeopleData from './peopleData';
import { ARTICLES_OPTION_CODENAME, PEOPLE_OPTION_CODENAME } from '../Constants';

export const processContentData = (codename: string) => {
    switch (codename) {
        case ARTICLES_OPTION_CODENAME:
            return ArticlesData();

        case PEOPLE_OPTION_CODENAME:
            return PeopleData();
        default:
            return null;
    }
};

export default {
    processContentData,
};
