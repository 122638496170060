import React from 'react';
import PropTypes from 'prop-types';
import SliderContainer from '~2-components/Slider/SliderContainer';

const NewsList = (props) => {
    const {
        title,
        urlSlug,
        children,
    } = props;

    const settings = {
        responsive: [
            {
                breakpoint: 9999,
                settings: 'unslick'
            },
            {
               breakpoint: 767,
               settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                    dots: true,
                    centerMode: false
               }
            }
        ]

    };

    return (
        <div className="news-list">
            <h2 className="news-list-title">{title}</h2>
            <a href={urlSlug} className="news-list-link">Read More</a>
            <SliderContainer
                settings={settings}
            >
                {children}
            </SliderContainer>
        </div>
    );
};

export default NewsList;

NewsList.propTypes = {
    title: PropTypes.string.isRequired,
    urlSlug: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};
