/* eslint-disable @typescript-eslint/camelcase */
import { ListingFilterOptionsItemNode } from "~/@types/listing";
import {
  FilterOptions,
  FilterOption,
} from "~/2-components/FilteredListing/FilteredListingType";
import TaxonomyArticleTypeData from "./TaxonomyArticleTypeData";
import TaxonomyYearData from "./TaxonomyYearData";
import MultiChoiceLocationData from "./MultichoiceLocationData";
import MultichoicePracticeAreaData from "./MultichoicePracticeAreaData";
import MultichoicePracticeAreaArticleData from "./MultichoicePracticeAreaArticleData";
import MultichoicePracticeAreaProfileData from "./MultichoicePracticeAreaProfileData";
import MultiChoicePublisherData from "./MultichoicePublisherData";
import {
  ARTICLE_TYPE_OPTION_CODENAME,
  YEAR_OPTION_CODENAME,
  LOCATION_OPTION_CODENAME,
  PRACTICE_AREA_OPTION_CODENAME,
  PUBLISHER_OPTION_CODENAME,
  PEOPLE_OPTION_CODENAME,
  ARTICLES_OPTION_CODENAME,
} from "../Constants";
import { FILTER_TYPE_KEYWORDS } from "~/2-components/FilteredListing/Constants/filter-types";

const getFODataByCodeName = (
  codename: string,
  listingType: string
): FilterOption | null => {
  switch (codename) {
    case ARTICLE_TYPE_OPTION_CODENAME:
      return TaxonomyArticleTypeData();
    case LOCATION_OPTION_CODENAME:
      return MultiChoiceLocationData();
    case YEAR_OPTION_CODENAME:
      return TaxonomyYearData();
    case PRACTICE_AREA_OPTION_CODENAME:
      if (listingType === ARTICLES_OPTION_CODENAME) {
        return MultichoicePracticeAreaArticleData();
      }
      if (listingType === PEOPLE_OPTION_CODENAME) {
        return MultichoicePracticeAreaProfileData();
      } else {
        return MultichoicePracticeAreaData();
      }
    case PUBLISHER_OPTION_CODENAME:
      return MultiChoicePublisherData();
    default:
      return null;
  }
};

const getKeywordsFO = (code: string) => {
  return {
    code,
    values: [],
  };
};

export interface ProcessFilterOptionsDataFunction {
  (
    linkedItems: ListingFilterOptionsItemNode[],
    listingType: string
  ): FilterOptions[];
}

export const processFilterOptionsData: ProcessFilterOptionsDataFunction = (
  linkedItems,
  listingType
) => {
  const convertedFilterOptions: FilterOptions[] = [];
  linkedItems.forEach((linkedItem) => {
    const {
      elements: { values, label, query_param, type },
    } = linkedItem;

    const codename = values.value.length > 0 ? values.value[0].codename : "";

    const FO =
      type.value[0].name === FILTER_TYPE_KEYWORDS
        ? getKeywordsFO(query_param.value) // using queryParam as the code value for Keywords
        : getFODataByCodeName(codename, listingType);

    if (FO) {
      convertedFilterOptions.push({
        label: label.value,
        queryParam: query_param.value,
        type: type.value[0].name,
        ...FO,
      });
    }
  });

  return convertedFilterOptions;
};

export default {
  processFilterOptionsData,
};
