/* eslint-disable @typescript-eslint/camelcase */
import { useStaticQuery, graphql } from 'gatsby';
import { TaxonomyNode } from '~/@types/taxonomy';
import { taxonomyNodeToFilterOption } from './FilterOptionsHelpers';

interface TaxonomyArticleType {
    kontentTaxonomy: TaxonomyNode;
}

const TaxonomyArticlesTypeData = () => {
    const data: TaxonomyArticleType = useStaticQuery(graphql`
        {
            kontentTaxonomy(system: { codename: { eq: "article_type" } }) {
                terms {
                    name
                }
                system {
                    codename
                }
            }
        }
    `);

    const taxonomyNode = data.kontentTaxonomy;

    return taxonomyNodeToFilterOption(taxonomyNode);
};

export default TaxonomyArticlesTypeData;
