import React from 'react';
import PropTypes from 'prop-types';

export const OpportunitiesContent = ({ children }) => (<>{children}</>);
export const OpportunitiesLinks = ({ children }) => (<>{children}</>);
export const OpportunitiesCallouts = ({ children }) => (<>{children}</>);

const CurrentOpportunities = (props) => {
    const { children } = props;

    const opportunitiesContent = children.filter(child => child.type === OpportunitiesContent);
    const opportunitiesLinks = children.filter(child => child.type === OpportunitiesLinks);
    const opportunitiesCallouts = children.filter(child => child.type === OpportunitiesCallouts);

    return (
        <div className="current-opportunities">
            <div className="text-wrapper">
                {opportunitiesContent}
            </div>
            <div className="opportunities-container">
                {opportunitiesLinks}
            </div>
            {opportunitiesCallouts}
        </div>
    );
};

CurrentOpportunities.propTypes = {
    children: PropTypes.node,
};

OpportunitiesContent.propTypes = {
    children: PropTypes.node,
};
OpportunitiesLinks.propTypes = {
    children: PropTypes.node,
};
OpportunitiesCallouts.propTypes = {
    children: PropTypes.node,
};

export default CurrentOpportunities;
