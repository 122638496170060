import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AllNewsArticleList from '~/2-components/AllNewsArticleList/AllNewsArticleList';

export function getAllNewsArticles() {
    const allPages = useStaticQuery(
        graphql`
        {
            allKontentItemNewsArticle(sort: {fields: elements___date___value, order: DESC}) {
                nodes {
                    elements {
                        page_url {
                            value
                        }
                        date {
                            value(formatString: "DD MMMM YYYY")
                        }
                    }
                    system {
                        name
                    }
                }
            }
        }
        `
    );

    let mapped = allPages.allKontentItemNewsArticle.nodes.map(item => {
        return {
            title: item.system.name,
            page_url: item.elements.page_url.value,
            date: item.elements.date.value
        };
    });

    mapped = mapped.filter(item => item.date !== null && (item.title !== '' || item.title === undefined));

    return mapped;
}

const CompactArticleListKontent = () => {
    return (
        <AllNewsArticleList 
            articles={getAllNewsArticles() || []}
        />
    );
};

export default CompactArticleListKontent;