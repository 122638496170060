import { useStaticQuery, graphql } from 'gatsby';
import { pageNodeToFilterOption } from './FilterOptionsHelpers';
import { LinkedItem } from '~/@types/pages';


interface LinkedItemNodeData {
    allKontentItemLocation: LinkedItem;
}

const MultiChoiceLocationData = () => {
    const data: LinkedItemNodeData = useStaticQuery(graphql`
        {
            allKontentItemLocation {
                nodes {
                    elements {
                        location_city {
                            value
                        }
                    }
                    system {
                        type
                        id
                    }
                }
            }
        }
    `);

    const LinkedItemNode = data.allKontentItemLocation.nodes;

    const convertedData = LinkedItemNode.map(node => {
        return {
            system:{
                id: node.system.id,
                type: node.system.type
            },
            elements:{
                title: {
                    value: node.elements.location_city.value
                },
            }
        };
    });
    return pageNodeToFilterOption(convertedData);
};

export default MultiChoiceLocationData;
