import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import RichText from '~3-data-components/RichText';
import LinkList from '~2-components/LinkList/LinkList';

const LinkListKontent = (props) => {
    if (!props.system) { return null; }
    const linkListContainerID = props.system.id;

    const allLinkListContainers = useStaticQuery(graphql`
        query allLinkLists {
            allKontentItemLinkList {
                nodes {
                    system {
                        id
                        type
                    }
                    elements {
                        title {
                            value
                        }
                        links {
                            value
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        allLinkListContainers.allKontentItemLinkList.nodes.find((n) => linkListContainerID === n.system.id)
    ), [allLinkListContainers, linkListContainerID]);

    if (!match.elements.links) { return null; }
    const richTextContent = match.elements.links;
    const title = match.elements.title ? match.elements.title.value : '';


    return (
        <LinkList title={title}>
            <RichText
                content={richTextContent}
            />
        </LinkList>
    );
};

LinkListKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    })
};

export default LinkListKontent;
