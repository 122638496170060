import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FilteredListing from '~/2-components/FilteredListing/FilteredListing';
import { KenticoLinkedItem } from '~/@types/kentico';
import { ListingNode } from '~/@types/listing';
import { processContentData } from './ContentData/ContentData';
import { processSettingsData } from './Settings/Settings';
import { processFilterOptionsData } from './FilterOptions/FilterOptions';

interface AllKontentItemListing {
    nodes: ListingNode[];
}

interface AllKontentItemListingData {
    allKontentItemListing: AllKontentItemListing;
}

const FilteredListingKontent = ({ system: { id } }: KenticoLinkedItem) => {
    const data: AllKontentItemListingData = useStaticQuery(graphql`
        {
            allKontentItemListing {
                nodes {
                    system {
                        id
                    }
                    elements {
                        display_content {
                            value {
                                name
                                codename
                            }
                        }
                        items_per_page {
                            value
                        }
                        query_param {
                            value
                        }
                        initial_sort_by_index {
                            value
                        }
                        sort_by {
                            value {
                                ... on kontent_item_listing_sort_by {
                                    elements {
                                        field {
                                            value {
                                                codename
                                            }
                                        }
                                        label {
                                            value
                                        }
                                        sorting {
                                            value {
                                                name
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        visibility {
                            value {
                                codename
                            }
                        }
                        filter_title_label {
                            value
                        }
                        search_title_label {
                            value
                        }
                        filter_options {
                            value {
                                ... on kontent_item_listing_filter_option {
                                    elements {
                                        label {
                                            value
                                        }
                                        query_param {
                                            value
                                        }
                                        type {
                                            value {
                                                name
                                            }
                                        }
                                        values {
                                            value {
                                                codename
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const match = useMemo(
        () => data.allKontentItemListing.nodes.find(
            (node: ListingNode) => id === node.system.id,
        ),
        [id],
    );
    if (!match) return null;

    const listingType = match.elements.display_content.value[0].codename || null;
    if (!listingType) return null;
    const contentData = processContentData(listingType);

    if (!contentData) return null;
    const settings = processSettingsData(match);
    const filterOptions = processFilterOptionsData(
        match.elements.filter_options.value,
        listingType
    );

    const newFLData = {
        filterOptions,
        settings,
        contentData,
    };

    return (
        <>
            <FilteredListing data={newFLData} tileType={listingType} />
        </>
    );
};

export default FilteredListingKontent;
