import { TaxonomyNode } from '~/@types/taxonomy';
import { FilterOptionValue, FilterOption } from '~/2-components/FilteredListing/FilteredListingType';
import { toSnakeCase } from '~/2-components/FilteredListing/utils/string';
import { ProfileLinkedItemsElement } from '~/@types/pages';

export const taxonomyNodeToFilterOption = (node: TaxonomyNode): FilterOption => {
    let code = '';

    if (!node) {
        return {
            code,
            values: [] as FilterOptionValue[],
        };
    }

    const convertedTerms = node.terms.map(term => {
        const { name } = term;
        return {
            param: toSnakeCase(name),
            value: name,
        };
    });
    const { codename } = node.system;
    code = codename;

    return {
        code: codename,
        values: convertedTerms as FilterOptionValue[],
    };
};

export const pageNodeToFilterOption = (nodes: any[]): FilterOption => {
    let code = '';
    // get the values from pageNode
    const values = nodes.map(node => {
        const { elements: { title: { value } }, system: { type } } = node;
        code = type;

        return {
            param: toSnakeCase(value),
            value,
        };
    });

    return {
        code,
        values,
    };
};

export default {
    taxonomyNodeToFilterOption,
    pageNodeToFilterOption,
};
