import React from 'react';
import PropTypes from 'prop-types';

const PageSection = (props) => {
    const {
        children,
        backgroundTheme,
        leftColContent,
        rightColContent,
        textLayout,
        leftAlignedContent
    } = props;

    return (
        <div className={`page-section ${backgroundTheme} ${textLayout} ${leftAlignedContent}`}>
            <div className="left-col">
                {children &&
                    children
                }

                {leftColContent &&
                    leftColContent
                }
            </div>
            {textLayout &&
                <div className="right-col">
                    {rightColContent}
                </div>
            }
        </div>
    );
};

export default PageSection;

PageSection.propTypes = {
    children: PropTypes.node,
    backgroundTheme: PropTypes.string,
    textLayout: PropTypes.string,
    leftAlignedContent: PropTypes.string,
    leftColContent: PropTypes.object,
    rightColContent: PropTypes.object
};
