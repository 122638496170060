/* eslint-disable @typescript-eslint/camelcase */
import { useStaticQuery, graphql } from 'gatsby';
import { pageNodeToFilterOption } from './FilterOptionsHelpers';
import { LinkedItemsElement, LinkedItemNode} from '~/@types/pages';

interface allKontentItemProfile {
    nodes: LinkedItemsElement;
}

interface LinkedItemNodeData {
    allKontentItemNewsArticle: LinkedItemNode;
}

const MultiChoicePublisherData = () => {
    const data: LinkedItemNodeData = useStaticQuery(graphql`
        {
            allKontentItemNewsArticle {
              nodes {
                  elements {
                    author {
                      value {
                        ... on kontent_item_profile {
                          id
                          elements {
                            first_name {
                              value
                            }
                            last_name {
                              value
                            }
                          }
                          system {
                            id
                            type
                          }
                        }
                      }
                    }
                  }
              }
            }
        }
    `);
    let convertedData: LinkedItemNode | [] = [];
    data.allKontentItemNewsArticle.nodes.map((node: any) => {
        node.elements.author.value.map((item: any) => {
            const {
                elements: {
                    first_name,
                    last_name,
                },
                system: {
                    type,
                    id
                },
            } = item
            const tempData = {
                system:{
                    id: id,
                    type: type
                },
                elements:{
                    title: {
                        value: `${first_name.value} ${last_name.value}`
                    },
                }
            };

            // filter out if it is a repeat of publisher, and if they are porevented form displaying in Aus listings
            if((convertedData.filter((data: { system: { id: any; }; }) => tempData.system.id === data.system.id).length <= 0)){
              convertedData.push(tempData);
            }
        });
    });
    return pageNodeToFilterOption(convertedData);
};

export default MultiChoicePublisherData;
