import React from 'react';
import PropTypes from 'prop-types';
import Icon from '~2-components/IconInline/IconInline';
import { Link } from 'gatsby';

export const Button = ({ href, target, skin, icon, children }) => (
    <Link
        to={`/${href}`}
        target={target ? target : '_self'}
        className={`btn-skin-${skin ? skin : '1'} ${icon ? 'btn-icon' : ''}`}
    >
        {icon && <Icon icon={icon} />} {children}
    </Link>
);

Button.propTypes = {
    skin: PropTypes.oneOf(['1', '2', '3']),
    href: PropTypes.string.isRequired,
    target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
    icon: PropTypes.string,
    children: PropTypes.node.isRequired
};

Button.defaultProps = {
    skin: '1',
    target: '_self'
};

export default Button;
