import React from 'react';
import PropTypes from 'prop-types';

import PageSectionKontent from '~3-data-components/PageSectionKontent';
import TileContainerKontent from '~3-data-components/TileContainerKontent';
import VideoPanelKontent from '~3-data-components/VideoPanelKontent';
import ContentIconTileKontent from '~3-data-components/ContentIconTileKontent';
import FeatureImageAndText from '~2-components/FeatureImageAndText/FeatureImageAndText';
import CheckListKontent from '~3-data-components/CheckListKontent';
import LinkList from '~3-data-components/LinkListKontent';
import AwardsAndAccoladesKontent from '~3-data-components/AwardsAndAccoladesKontent';
import AwardItemKontent from '~3-data-components/AwardItemKontent';
import OptionsTile from '~2-components/OptionsTile/OptionsTile';
import AccordionKontent from '~3-data-components/AccordionKontent';
import TestimonialCarousel from '~2-components/TestimonialCarousel/TestimonialCarousel';
import TestimonialItem from '~2-components/TestimonialItem/TestimonialItem';
import ProfileSlider from '~2-components/ProfileSlider/ProfileSlider';
import ProfileTile from '~2-components/ProfileTile/ProfileTile';
import CurrentOpportunitiesKontent from '~3-data-components/CurrentOpportunitiesKontent';
import CallUsCalloutKontent from '~3-data-components/CallUsCalloutKontent';
import ContentImageTile from '~2-components/ContentImageTile/ContentImageTile';
import LocationList from '~2-components/LocationList/LocationList';
import IframeFormKontent from '~3-data-components/IframeFormKontent';
import NewsListKontent from '~3-data-components/NewsListKontent';
import FilteredListingKontent from '~3-data-components/FilteredListing/FilteredListingKontent';
import CompactListKontent from '~/3-data-components/CompactArticleListKontent';


export const allContentTypes = {
    content_icon_tile: ContentIconTileKontent,
    page_section: PageSectionKontent,
    video_panel: VideoPanelKontent,
    tile_container: TileContainerKontent,
    feature_image_and_text: FeatureImageAndText,
    checklist: CheckListKontent,
    link_list: LinkList,
    awards_and_accolades: AwardsAndAccoladesKontent,
    award: AwardItemKontent,
    options_tile: OptionsTile,
    accordion: AccordionKontent,
    testimonial_panel: TestimonialCarousel,
    testimonial: TestimonialItem,
    profile_listing: ProfileSlider,
    profile: ProfileTile,
    current_opportunities: CurrentOpportunitiesKontent,
    call_us_callout: CallUsCalloutKontent,
    content_image_tile: ContentImageTile,
    locationlist: LocationList,
    form: IframeFormKontent,
    latest_news_list: NewsListKontent,
    listing: FilteredListingKontent,
    compact_article_list: CompactListKontent,
};

const LinkedItem = props => {
    const contentType = props.system.type;
    const matchInAllTypes = Object.prototype.hasOwnProperty.call(allContentTypes, contentType);

    if (props.system && !matchInAllTypes) {
        return <h2>{contentType} has not been hooked up to Kontent and/or LinkedItem</h2>;
    }

    const SpecificImport = allContentTypes[contentType];

    return <SpecificImport {...props} />;
};

export default LinkedItem;

LinkedItem.propTypes = {
    system: PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
    }).isRequired
};
