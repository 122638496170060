import React from 'react';
import PropTypes from 'prop-types';
import Icon from '~2-components/IconInline/IconInline';

const CallUsCallout = (props) => {
    const { children } = props;

    return (
        <div className="call-us-callout">
            <Icon
                icon={`phone`}
            />
            <div className="callout-text">
                {children}
            </div>
        </div>
    )
};

CallUsCallout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CallUsCallout;
