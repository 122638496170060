import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import parseHTML from 'html-react-parser';
import { useStaticQuery, graphql } from 'gatsby';
import LinkedItem from '~3-data-components/LinkedItem';
import TileContainer from '~2-components/TileContainer/TileContainer';

const TileContainerKontent = (props) => {
    const { system } = props;
    const { id: tileContainerID } = system;

    const allTileContainers = useStaticQuery(graphql`
        query allTileContainers {
            allKontentItemTileContainer {
                nodes {
                    system {
                        id
                    }
                    elements {
                        number_of_columns {
                            value {
                                codename
                            }
                        }
                        number_the_tiles {
                            value {
                                codename
                            }
                        }
                        tile_layout {
                            value {
                                codename
                            }
                        }
                        tiles {
                            modular_content {
                                system {
                                    id
                                    type
                                    codename
                                }
                            }
                            value
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        allTileContainers.allKontentItemTileContainer.nodes.find((n) => tileContainerID === n.system.id)
    ), [ allTileContainers, tileContainerID]);

    const {
        tiles,
        number_of_columns,
        number_the_tiles,
        tile_layout
    } = match.elements;

    // use HTML to ensure Kontent tile order maintained
    const tileHTML = parseHTML(tiles.value);
    const tileCodenames = tileHTML.length > 0 ? tileHTML.filter(tile => tile.type === 'object').map(tile => tile.props['data-codename']) : tileHTML.props['data-codename'];
    const orderedTileItems = typeof tileCodenames === 'object' ? tileCodenames.map(codename => tiles.modular_content.find(match => match.system.codename === codename)) : [tiles.modular_content.find(match => match.system.codename === tileCodenames)];

    const numberedTiles = number_the_tiles.value.length ? true : false;

    function createStep(index) {
        let step = index + 1;
        step.toString();

        if (step <= 9) {
            step = '0' + step;
            return step;
        }
        return step;
    }

    const isHomepageLayout = tile_layout.value.length > 0 && tile_layout.value[0].codename === 'homepage_layout';

    return (
        <TileContainer
            columns={number_of_columns.value.length ? number_of_columns.value[0].codename : ''}
            homepageLayout={isHomepageLayout}
        >
            {orderedTileItems.map((tile, index) => {
                const stepNumber = numberedTiles ? createStep(index) : null;
                // check if first tile of a homepage layout tile container, and give it 'featured_tile' layout
                const tileLayout = tile_layout.value.length ? ((isHomepageLayout && index === 0) ? 'featured_layout' : tile_layout.value[0].codename) : '';

                return (
                    <LinkedItem
                        key={index}
                        tileLayout={tileLayout}
                        step={stepNumber}
                        linkTxt={isHomepageLayout ? 'Learn more' : ''}
                        {...tile}
                    />
                );
            })}
        </TileContainer>
    );
};

TileContainerKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.required
    }).isRequired
};

export default TileContainerKontent;

