import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import RichText from '~3-data-components/RichText';

const OptionsTile = (props) => {
    let title, richTextContent;

    if (props.system) {
        // data comes from CMS

        const optionsID = props.system.id;

        const optionsTileData = useStaticQuery(graphql`
            query allOptionsTiles {
                allKontentItemOptionsTile {
                    nodes {
                        system {
                            id
                        }
                        elements {
                            blurb {
                                value
                            }
                            title {
                                value
                            }
                        }
                    }
                }
            }
        `);

        const match = useMemo(() => (
            optionsTileData.allKontentItemOptionsTile.nodes.find((n) => optionsID === n.system.id)
        ), [ optionsTileData, optionsID]);

        title = match.elements.title.value;
        richTextContent = match.elements.blurb;

    } else {
        title = props.title;
    }

    return (
        <div className="options-tile">
            <h3 className="options-tile-title">{title}</h3>

            <div className="options-tile-content">
                {!props.children &&
                    <RichText
                        content={richTextContent}
                    />
                }

                {props.children &&
                    props.children
                }
            </div>
        </div>
    );
};

OptionsTile.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.required
    }),
    title: PropTypes.string,
    children: PropTypes.node,
};

export default OptionsTile;

