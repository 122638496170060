import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Accordion from '~2-components/Accordion/Accordion';
import AccordionItemKontent from '~3-data-components/AccordionItemKontent';

const AccordionKontent = (props) => {
    const { system } = props;
    const { id: accordionID } = system;

    const allAccordions = useStaticQuery(graphql`
        query allAccordions {
            allKontentItemAccordion {
                nodes {
                    system {
                        id
                    }
                    elements {
                        items {
                            value {
                                ... on kontent_item_accordion_item {
                                    system {
                                        id
                                        type
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        allAccordions.allKontentItemAccordion.nodes.find((n) => accordionID === n.system.id)
    ), [allAccordions, accordionID]);

    const accordionItems = match.elements.items.value;

    return (
        <Accordion>
            {accordionItems.map((item, i) => {
                return (
                    <AccordionItemKontent key={i} {...item} />
                );
            })}
        </Accordion>
    );
};

export default AccordionKontent;

AccordionKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.required
    }),
};
