import { Elements } from '@kentico/kontent-delivery';
import { toSnakeCase } from '~/2-components/FilteredListing/utils/string';
import { LinkedItemNode, ProfileLinkedItemsElement } from '~/@types/pages';
import { FILTER_OPTION_TYPE_LINKED_ITEMS, FILTER_OPTION_TYPE_TAXONOMY } from '../Constants';

export type FilterOptionsType =
    typeof FILTER_OPTION_TYPE_TAXONOMY |
    typeof FILTER_OPTION_TYPE_LINKED_ITEMS;

type FilterOptionsCategoryDataCombination =
    Elements.TaxonomyElement |
    ProfileLinkedItemsElement;

interface FilterOptionsCategoryData {
    data: FilterOptionsCategoryDataCombination;
    codename?: string;
    type: FilterOptionsType;
}

const processLinkedItemsData = (linkedNode: any, codename?: string) => {
    if (!linkedNode) { return; }
    const { value } = linkedNode;
    if(!codename) {return null;}
    return {
        code: toSnakeCase(codename || ''),
        values: value.map((item: LinkedItemNode ) => {
            if(!item.elements){return { value: '', param: '' };}
            if(codename === 'profile') {
                const title = `${item.elements.first_name.value} ${item.elements.last_name.value}`;
                return { value: title, param: toSnakeCase(title) };
            }
            if(codename === 'location') {
                const title = `${item.elements.location_city.value}`;
                return { value: title, param: toSnakeCase(title) };
            }
            return { value: item.elements.title.value, param: toSnakeCase(item.elements.title.value) };
        }),
    };
};

const processTaxonomyData = (taxonomy: Elements.TaxonomyElement) => {
    if (!taxonomy) { return; }
    return {
        code: taxonomy.taxonomyGroup,
        values: taxonomy.value.map(item => { return { value: item.name, param: toSnakeCase(item.name) }; }),
    };
};

export const processFilterOptionData = (categories: FilterOptionsCategoryData[]) => categories.map(category => {
    switch (category.type) {
        case FILTER_OPTION_TYPE_LINKED_ITEMS:
            return processLinkedItemsData(category.data as any, category.codename);
        // Default is 'taxonomy'
        default: return processTaxonomyData(category.data as Elements.TaxonomyElement);
    }
});

export default {
    processFilterOptionData,
};
