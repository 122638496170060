/* eslint-disable @typescript-eslint/camelcase */
import { ElementModels } from '@kentico/kontent-delivery';
import { ListingNode, ListingSortByNode } from '~/@types/listing';
import { toSnakeCase } from '~2-components/FilteredListing/utils/string';
import { Settings } from '~/2-components/FilteredListing/FilteredListingType';
import { ARTICLES_OPTION_CODENAME } from '../Constants';


export interface ProcessSettingsFunction {
    (match: ListingNode): Settings;
}

const processVisibilityData = (value: ElementModels.MultipleChoiceOption[]) => {
    return {
        showPagination: !!value.find(item => item.codename === 'show_pagination'),
        showResultCount: !!value.find(item => item.codename === 'show_result_count'),
        showActiveFilters: !!value.find(item => item.codename === 'show_active_filters'),
    };
};

const processSortByData = (linkedItems: ListingSortByNode[]) => {
    const sortBySettings = linkedItems.map(item => {
        const { elements: { field, label, sorting } } = item;
        return {
            label: label.value,
            field: field.value[0].codename,
            param: toSnakeCase(field.value[0].codename),
            ascending: sorting.value[0].name === 'ascending',
        };
    });

    return sortBySettings;
};

// Hard coded keywordSearchableFields based on Display Content
// such as this really depend on FED variable on dataContent
const processKeywordSearchableFields = (displayContentCodeName: string) => {
    switch (displayContentCodeName) {
        case ARTICLES_OPTION_CODENAME: return ['title', 'introduction', 'authorName'];
        default: return [];
    }
};

export const processSettingsData: ProcessSettingsFunction = match => {
    const {
        elements: {
            items_per_page, sort_by, visibility, active_filters_label, keyword_placeholder_text, display_content, query_param, filter_title_label, search_title_label, initial_sort_by_index
        },
    } = match;

    const itemsPerPage = items_per_page.value ? items_per_page.value : 60;
    const visibilitySettings = processVisibilityData(visibility.value);
    const sortBySettings = processSortByData(sort_by.value);
    const initialSort = initial_sort_by_index && initial_sort_by_index.value ? initial_sort_by_index.value : 0;
    const keywordSearchableFields = processKeywordSearchableFields(display_content.value[0].codename);
    const activeFiltersLabelValue = active_filters_label && active_filters_label.value ? active_filters_label.value : '';
    const keywordPlaceholderTextValue = keyword_placeholder_text && keyword_placeholder_text.value ? keyword_placeholder_text.value : '';
    const queryParamValue = query_param && query_param.value ? query_param.value : '';
    const filterTitleLabel = filter_title_label && filter_title_label.value ? filter_title_label.value : '';
    const searchTitleLabel = search_title_label && search_title_label.value ? search_title_label.value : '';

    return {
        activeFiltersLabel: activeFiltersLabelValue,
        keywordPlaceholderText: keywordPlaceholderTextValue,
        keywordSearchableFields,
        itemsPerPage,
        filterTitleLabel,
        searchTitleLabel,
        queryParam: queryParamValue,
        ...visibilitySettings,
        sortBy: sortBySettings,
        initialSort: initialSort
    };
};

export default {
    processSettingsData,
};
