import React from 'react';
import PropTypes from 'prop-types';

const Accordion = ( props ) => {
    const { children } = props;

    return (
        <div className="accordion">
            {children}
        </div>
    );
};

export default Accordion;

Accordion.propTypes = {
    children: PropTypes.node.isRequired,
};
