import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '~2-components/IconInline/IconInline';

const AccordionItem = (props) => {
    const {
        title,
        content
    } = props;

    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");

    const contentRef = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "open" : "");
        setHeightState(
            setActive === "open" ? "0px" : `${contentRef.current.scrollHeight}px`
        );
    }

    return (
        <div
            className={`accordion-item ${setActive}`}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
        >
            <div className={`accordion-heading`} onClick={toggleAccordion}>
                <Icon icon={`chevron-down`} />
                <h2
                    className="accordion-title"
                    itemProp="name"
                >{title}</h2>
            </div>
            <div
                ref={contentRef} style={{ maxHeight: `${setHeight}` }}
                className={`accordion-content-wrapper`}
                itemScope
                itemProp="acceptedAnswer"
                itemType="https://schema.org/Answer"
            >
                <div
                    className={`accordion-content`}
                    itemProp="text"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </div>
    );
};

export default AccordionItem;

AccordionItem.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};
