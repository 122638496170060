import React from 'react';
import PropTypes from 'prop-types';
import Icon from '~2-components/IconInline/IconInline';
import { Link } from 'gatsby';

const OpportunityItem = ({ opportunityText, linkUrl }) => {

    return (
        <Link to={`/${linkUrl}`} className="opportunity-item">
            <div className="chevron">
                <Icon
                    icon={`chevron-right`}
                />
            </div>
            <h5>{opportunityText}</h5>
            <div className="apply-arrow">
                <p>Apply</p>
                <Icon
                    icon={`arrow-right`}
                />
            </div>
        </Link>
    );
};

OpportunityItem.propTypes = {
    opportunityText: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
};

export default OpportunityItem;
