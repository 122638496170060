import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import CheckList from '~2-components/CheckList/CheckList';
import RichText from '~3-data-components/RichText';

const CheckListKontent = (props) => {
    if (!props.system) {
        return '';
    }
    const checklistContainerID = props.system.id;
    const allChecklistContainers = useStaticQuery(graphql`
        query allChecklists {
            allKontentItemChecklist {
                nodes {
                    system {
                        id
                        name
                        type
                    }
                    elements {
                        list {
                            modular_content {
                                system {
                                    id
                                    type
                                    codename
                                }
                            }
                            links {
                                codename
                                link_id
                                type
                                url_slug
                            }
                            value
                        }
                    }
                }
            }
        }

    `);

    const match = useMemo(() => (
        allChecklistContainers.allKontentItemChecklist.nodes.find((n) => checklistContainerID === n.system.id)
    ), [allChecklistContainers, checklistContainerID]);

    const previewErrorMsg = {
        value: '<h3>No checklist items added</h3>',
    };

    const content = match.elements.list.value !== '<p><br></p>' ? match.elements.list : previewErrorMsg;

    return (
        <CheckList>
            <RichText
                content={content}
                links={content.links}
            />
        </CheckList>
    );
};

CheckListKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
};

export default CheckListKontent;
