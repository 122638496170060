import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import RichText from '~3-data-components/RichText';
import IframeForm from '~2-components/IframeForm/IframeForm';

const IframeFormKontent = (props) => {
    const { system } = props;
    const { id: iframeFormId } = system;

    const allIframeForms = useStaticQuery(
        graphql`
            query allIframeForms {
                allKontentItemForm {
                    nodes {
                        system {
                            id
                            type
                        }
                        elements {
                            title {
                                value
                            }
                            embedded_forms_list__embedded_forms_list {
                                value {
                                    codename
                                }
                            }
                            formstack_embed {
                                value
                            }
                            blurb {
                                value
                            }
                            image {
                                value {
                                    description
                                    name
                                    fluid(maxWidth: 584) {
                                        ...KontentAssetFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
                allKontentItemSiteSettings {
                    nodes {
                        elements {
                            embed_url {
                                value
                            }
                        }
                    }
                }
            }
    `);

    const match = useMemo(() => (
        allIframeForms.allKontentItemForm.nodes.find((n) => iframeFormId === n.system.id)
    ), [allIframeForms, iframeFormId]);

    const {
        title: { value: cmsTitle },
        embedded_forms_list__embedded_forms_list: { value: embedID },
        formstack_embed: { value: formstackEmbed },
        blurb: cmsBlurb,
        image: { value: cmsImgData }
    } = match.elements;

    const title = cmsTitle ? cmsTitle : '';
    const formId = embedID.length > 0 && embedID[0].codename !== 'none' ? embedID[0].codename : '';
    const content = cmsBlurb ? cmsBlurb : '';
    const apiURL = allIframeForms.allKontentItemSiteSettings.nodes[0].elements.embed_url.value;
    const embedURL = formId ? `${apiURL}${formId}` : null;

    //gatsby image
    const imageAttr = cmsImgData.length > 0 ? cmsImgData[0] : {};
    const description = imageAttr.description;
    const id = imageAttr.name;
    const image = imageAttr.fluid;

    if (cmsImgData.length === 0) {
        return (
            <IframeForm
                title={title}
                embedURL={embedURL}
                embedCode={!embedURL ? formstackEmbed : null}
            >
                <RichText content={content} />
            </IframeForm>
        );
    }

    return (
        <IframeForm
            title={title}
            embedURL={embedURL}
            embedCode={!embedURL ? formstackEmbed : null}
            cmsImage={<GatsbyImage
                key={id}
                alt={description}
                fluid={image}
            />}
        >
            <RichText content={content} />
        </IframeForm>
    );
};

export default IframeFormKontent;

IframeFormKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    title: PropTypes.string,
    content: PropTypes.string,
    formId: PropTypes.string,
    imgUrl: PropTypes.string,
    imgAlt: PropTypes.string,
    children: PropTypes.node,
};
