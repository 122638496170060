import React from 'react';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';

const InlineAsset = ({ description, id, image, width, height }) => {
    // console.log('description' , description);
    // console.log('id' , id);
    // console.log('image' , image);
    // console.log('width' , width);
    // console.log('height' , height);

    {/*
        Why is this const here? Gatsby Image lets you query for fixed images, where you know the width and height, or fluid images that fill the container.

        We don't know the height of inline assets. We CAN query that but we can't use it as a variable to pass to GatsbyImage at the same time.

        Thus we have to query for fluid images, and use the images width and height into to make sure that images that are smaller than the contain don't expand to fill it.
    */}
    const maxWidth = `${width}px`;

    return  <GatsbyImage key={id} alt={description} fluid={image} style={{width: '100%', maxWidth: maxWidth}} />;
};

export default InlineAsset;

InlineAsset.propTypes = {
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    image: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        base64: PropTypes.number.isRequired,
        sizes: PropTypes.number.isRequired,
        src: PropTypes.number.isRequired,
        srcSet: PropTypes.number.isRequired
    }).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};
