import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import RichText from '~3-data-components/RichText';
import CallUsCallout from '~2-components/CallUsCallout/CallUsCallout';

const CallUsCalloutKontent = (props) => {
    const { system } = props;
    const { id: callUsCalloutID } = system;

    const allCallUsCallouts = useStaticQuery(
        graphql`
            query allCallUsCallouts {
                allKontentItemCallUsCallout {
                    nodes {
                        system {
                            id
                        }
                        elements {
                            call_us_callout {
                                value
                            }
                        }
                    }
                }
            }
    `);

    const match = useMemo(() => (
        allCallUsCallouts.allKontentItemCallUsCallout.nodes.find((n) => callUsCalloutID === n.system.id)
    ), [ allCallUsCallouts, callUsCalloutID]);

    const richTextContent = match.elements.call_us_callout;

    return (
        <CallUsCallout>
            <RichText
                content={richTextContent}
            />
        </CallUsCallout>
    );
};

CallUsCalloutKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
};

export default CallUsCalloutKontent;
