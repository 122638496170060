/* eslint-disable @typescript-eslint/camelcase */
import { useStaticQuery, graphql } from 'gatsby';
import { pageNodeToFilterOption } from './FilterOptionsHelpers';
import {LinkedItemNode} from '~/@types/pages';


interface PracticeAreasNodeData {
    allKontentItemGeneralContent: LinkedItemNode;
}

const MultichoicePracticeAreaArticleData = () => {
    const data: PracticeAreasNodeData = useStaticQuery(graphql`
        {
            allKontentItemGeneralContent(filter: {elements: {include_as_filter_option: {value: {elemMatch: {codename: {eq: "article_listing"}}}}}}) {
                nodes {
                    elements {
                        title {
                            value
                        }
                    }
                    system {
                        type
                        id
                    }
                }
            }
        }
    `);

    const PracticeAreasNode = data.allKontentItemGeneralContent.nodes;
    return pageNodeToFilterOption(PracticeAreasNode);
};

export default MultichoicePracticeAreaArticleData;
