import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import OpportunityItem from '~2-components/CurrentOpportunities/OpportunityItem';
import CurrentOpportunities, { OpportunitiesContent, OpportunitiesLinks, OpportunitiesCallouts } from '~2-components/CurrentOpportunities/CurrentOpportunities';
import RichText from '~3-data-components/RichText';
import LinkedItem from '~3-data-components/LinkedItem';


const CurrentOpportunitiesKontent = (props) => {
    const { system } = props;
    const { id: currentOpportunitiesID } = system;

    const allCurrentOpportunities = useStaticQuery(
        graphql`
            query allCurrentOpportunities {
                allKontentItemCurrentOpportunities {
                    nodes {
                        system {
                            id
                        }
                        elements {
                            contact_person_details {
                                modular_content {
                                    system {
                                        id
                                        type
                                    }
                                }
                            }
                            current_opportunities_content {
                                value
                            }
                            current_opportunities_list {
                                value {
                                    ... on kontent_item_general_content {
                                        elements {
                                            page_slug {
                                                value
                                            }
                                            title {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    `);

    const match = useMemo(() => (
        allCurrentOpportunities.allKontentItemCurrentOpportunities.nodes.find((n) => currentOpportunitiesID === n.system.id)
    ), [allCurrentOpportunities, currentOpportunitiesID]);

    const {
        current_opportunities_content,
        current_opportunities_list,
        contact_person_details,
    } = match.elements;

    return (
        <CurrentOpportunities>
            <OpportunitiesContent>
                <RichText
                    content={current_opportunities_content}
                />
            </OpportunitiesContent>
            <OpportunitiesLinks>
                {current_opportunities_list.value.map((item, i) => {
                    return (
                        <OpportunityItem
                            key={i}
                            opportunityText={item.elements.title.value}
                            linkUrl={item.elements.page_slug.value}
                        />
                    );
                })}
            </OpportunitiesLinks>
            <OpportunitiesCallouts>
                {contact_person_details.modular_content.map((item, i) => {
                    return (
                        <LinkedItem key={i} {...item}/>
                    );
                })}
            </OpportunitiesCallouts>
        </CurrentOpportunities>
    );
};

CurrentOpportunitiesKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
};

export default CurrentOpportunitiesKontent;
