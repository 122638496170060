import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ProfileTile from '~2-components/ProfileTile/ProfileTile';
import SliderContainer from '~2-components/Slider/SliderContainer';
import ProfileTileKontent from '~3-data-components/ProfileTileKontent';

const ProfileSlider = (props) => {
    let tiles;

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]

    };

    if (props.system) {
        // data comes from CMS

        const profileSliderID = props.system.id;

        const allprofileSliders = useStaticQuery(
            graphql`
                query ProfileItems {
                    allKontentItemProfileListing {
                        nodes {
                            system {
                                id
                            }
                            elements {
                                profiles {
                                    value {
                                        system {
                                          id
                                        }
                                      }
                                }
                            }
                        }
                    }
                }
        `);

        const match = useMemo(() => (
            allprofileSliders.allKontentItemProfileListing.nodes.find((n) => profileSliderID === n.system.id)
        ), [ allprofileSliders, profileSliderID]);

        tiles = match.elements.profiles.value;

    } else {
        // data comes from props
        tiles = props.tiles;
    }

    return (
        <div className="profile-slider">
            <div className="container">
                <SliderContainer
                    settings={settings}
                >
                    {tiles.map((item, i) => {
                        if(item.system){
                            return (
                                <ProfileTileKontent key={item.system.id} system={item.system} />
                            );
                        } else {
                            return (
                                <ProfileTile
                                    key={i}
                                    imgUrl={item.imgUrl}
                                    imgAlt={item.imgAlt}
                                    accredited={item.accredited}
                                    firstName={item.firstName}
                                    lastName={item.lastName}
                                    position={item.position}
                                    phone={item.phone}
                                    email={item.email}
                                    linkedIn={item.linkedIn}
                                    urlSlug={item.urlSlug}
                                    urlText={item.urlText}
                                    {...item}
                                />
                            );
                        }
                    })}
                </SliderContainer>
            </div>
        </div>
    );
};

export default ProfileSlider;

ProfileSlider.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    tiles: PropTypes.array,
};
