import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NewsTileKontent from '~3-data-components/NewsTileKontent';
import NewsList from '~2-components/NewsList/NewsList';
import { useStaticQuery, graphql } from 'gatsby';

const NewsListKontent = (props) => {
    const {pageInfo, relatedAreas, manualArticles} = props;
    let  title, newsTiles;

    const allNewsData = useStaticQuery(
        graphql`
            query AllNewsLists {
                allKontentItemLatestNewsList {
                    nodes {
                        system {
                            id
                        }
                        elements {
                            title {
                                value
                            }
                            recent_articles___manual_selection {
                                value {
                                    system {
                                        id
                                        type
                                    }
                                }
                            }
                        }
                    }
                }
                allKontentItemNewsArticle {
                    nodes {
                        system {
                            id
                        }
                        elements {
                            practice_areas {
                                value {
                                    system {
                                        id
                                    }
                                }
                            }
                            date {
                                value(difference: "days")
                            }
                            author {
                                value {
                                    system {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
                allKontentItemSiteSettings {
                    nodes {
                        elements {
                            news_listing_page {
                                value {
                                    ... on kontent_item_general_content {
                                        elements {
                                            page_slug {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    `);

    const allNewsArticles = allNewsData.allKontentItemNewsArticle.nodes;
    const urlSlug = allNewsData.allKontentItemSiteSettings.nodes[0].elements.news_listing_page.value[0].elements.page_slug.value;

    // Sort articles by date from newest to oldest
    function sortByDate(articles) {
        return articles.sort((a, b) =>parseFloat(a.elements.date.value) - parseFloat(b.elements.date.value));
    }

    // Get additional latest articles
    function getAdditionalArticles(articlesArray, numRequired) {
        const articleIds = articlesArray.map(item => item.system.id);
        // filter out any articles already in the array, and ensure news article does not show as related on its own page
        const additionalArticles = sortByDate(allNewsArticles).filter(item => !articleIds.includes(item.system.id)).filter(item => !pageInfo.id.includes(item.system.id));
        const numArticlesRequired = additionalArticles.slice(0, numRequired);
        const newArticles = articlesArray.concat(numArticlesRequired);
        const articles = sortByDate(newArticles);

        return articles;
    }

    // Use article ID to retrieve article object data
    function generateArticleObject(items){
        const articleObjects = items.map(item => allNewsArticles.find((n) => item.system.id === n.system.id));

        return articleObjects;
    }

    // Create news list based on manually selected articles
    function generateManualSelection(items) {
        let articles = generateArticleObject(items);
        articles = articles.length < 3 ? getAdditionalArticles(articles, (3-items.length)) : articles;

        return articles;
    }

    // Search all articles by related area
    function generateByRelatedPage(relatedInfo){
        let relatedId, relatedType;
        let matchedArticles = [];

        // find all matching articles by related id
        function relatedArticles(id) {
            const articles = allNewsArticles.filter(item => {
                const relatedAreas = relatedType === 'profile' ? item.elements.author.value : item.elements.practice_areas.value;
                const matchedItems = relatedAreas.find((n) => id === n.system.id);
                return matchedItems;
            });
            return articles;
        }

        // check if there are multiple related areas
        if (relatedInfo.length > 0){
            relatedId = relatedInfo.map(item => item.system.id);
            relatedType = relatedInfo[0].system.type;
            const allRelatedArticles = [];
            relatedId.map(id => {
                const matched = relatedArticles(id);
                allRelatedArticles.push(...matched);
            });
            // remove any duplicate articles that are related to multiple queried practice areas
            const duplicatesRemoved = [...new Set(allRelatedArticles)];
            // ensure news article does not show as related on its own page
            matchedArticles = duplicatesRemoved.filter(item => !pageInfo.id.includes(item.system.id));

        } else {
            relatedId = relatedInfo.id;
            relatedType = relatedInfo.type;
            matchedArticles = relatedArticles(relatedId);
        }

        const sortedArticles = sortByDate(matchedArticles);

        const articles = sortedArticles.length >= 3 ? sortedArticles.splice(0, 3) : getAdditionalArticles(sortedArticles, (3-sortedArticles.length));

        return articles;
    }

    // NewsList is from a News Article page
    if (pageInfo && pageInfo.type === 'news_article') {
        title = 'Related Articles';
        const selectedArticles = manualArticles.length ? generateManualSelection(manualArticles) : '';
        newsTiles = selectedArticles.length ? selectedArticles : generateByRelatedPage(relatedAreas);
    }

    // NewsList is from a Profile page
    if (pageInfo && pageInfo.type === 'profile') {
        newsTiles = generateByRelatedPage(pageInfo);
        title = 'Related Articles';
    }

    // NewsList is from a RichText component
    if (pageInfo && pageInfo.type === 'general_content') {
        const newsListId = props.system.id;

        const match = useMemo(() => (
            allNewsData.allKontentItemLatestNewsList.nodes.find((n) => newsListId === n.system.id)
        ), [allNewsData, newsListId]);

        //Check if articles have been manually chosen in the CMS
        const manuallySelected = match.elements.recent_articles___manual_selection.value;
        const selectedArticles = manuallySelected.length ? generateManualSelection(manuallySelected) : '';

        // If no articles have been chosen in CMS, generate articles by related page
        newsTiles = selectedArticles.length ? selectedArticles : generateByRelatedPage(pageInfo);
        title = match.elements.title.value.length ? match.elements.title.value : 'Related Articles';
    }

    return (
        <NewsList
            title={title}
            urlSlug={urlSlug}
        >
            {newsTiles && newsTiles.map((item, i) => {
                return (
                    <NewsTileKontent
                        key={i}
                        {...item}
                    />
                );
            })}
        </NewsList>
    );
};

export default NewsListKontent;

NewsListKontent.propTypes = {
    pageInfo: PropTypes.object.isRequired,
    relatedAreas: PropTypes.array,
    manualArticles: PropTypes.array,
    system: PropTypes.shape({
        id: PropTypes.string,
    }),
};
