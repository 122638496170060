import React from 'react';
import PropTypes from 'prop-types';
import IconIllustration from '~2-components/IconIllustration/IconIllustration';
import { Link } from 'gatsby';
import Button from '~/1-core/buttons/Button';

const ContentIconTile = (props) => {

        const {icon, title, blurb, linkUrl, step, linkTxt, tileLayout, numberedTiles} = props;

    const isLink = typeof linkUrl === 'string';

    const TileContent = () => {
        const tileIconEl = tileLayout === 'featured_layout' ?
        (
            <div className="icon-wrapper">
                <IconIllustration icon={icon} />
            </div>
        ) :
        (
            <IconIllustration icon={icon} />
        );

        return (
            <>
                {step &&
                    <span className="claim-step">{step}</span>
                }
                <div className="tile-content">
                    {tileIconEl}
                    <h4 className="tile-title">{title}</h4>
                    <div className="blurb" dangerouslySetInnerHTML={{__html: blurb}} />
                    {(tileLayout === 'featured_layout' || tileLayout === 'homepage_layout') && linkTxt && (
                        <Button
                            href={linkUrl}
                        >
                            {linkTxt}
                        </Button>
                    )}
                </div>
            </>
        );
    };

    if (isLink) {
        return (
            <Link to={`/${linkUrl}`} className={`content-icon-tile ${tileLayout !== 'default' ? tileLayout : ''} ${numberedTiles}`}>
                <TileContent />
            </Link>
        );
    }
    return (
        <div className={`content-icon-tile ${tileLayout !== 'default' ? tileLayout : ''} ${numberedTiles}`}>
            <TileContent />
        </div>
    );
};

export default ContentIconTile;

ContentIconTile.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    blurb: PropTypes.string,
    tileLayout: PropTypes.string,
    step: PropTypes.string,
    numberedTiles: PropTypes.string,
    linkUrl: PropTypes.string,
    linkTxt: PropTypes.string,
};

ContentIconTile.defaultProps = {
    tileLayout: ''
};

