import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from '~2-components/IconInline/IconInline';
import RichText from '~3-data-components/RichText';
import formatPhoneNumber from '~utils/formatPhoneNumber';
import { useStaticQuery, graphql } from 'gatsby';

const LocationItem = (props) => {
    let displayStreet, city, street, state, postcode, phone, fax;
    const DEFAULT_EMPTY_ADDRESS = '<p><br></p>';

    if (props.system) {
        // data comes from CMS

        const locationItemId = props.system.id;

        const allLocationItems = useStaticQuery(
            graphql`
                query allLocationItems {
                    allKontentItemLocation {
                        nodes {
                            system {
                                id
                                type
                            }
                            elements {
                                state {
                                    value
                                }
                                postcode {
                                    value
                                }
                                location_phone_number {
                                    value
                                }
                                location_city {
                                    value
                                }
                                location_fax {
                                    value
                                }
                                location_address {
                                    value
                                }
                                location_address_new {
                                    value
                                }
                            }
                        }
                    }
                }
        `);

        const match = useMemo(() => (
            allLocationItems.allKontentItemLocation.nodes.find((n) => locationItemId === n.system.id)
        ), [allLocationItems, locationItemId]);

        city = match.elements.location_city.value;
        displayStreet = match.elements.location_address_new.value;
        if (displayStreet === '' || displayStreet === DEFAULT_EMPTY_ADDRESS) {
            displayStreet = `<p>${match.elements.location_address.value}</p>`;
        }
        street = match.elements.location_address.value;
        state = match.elements.state.value;
        postcode = match.elements.postcode.value;
        phone = match.elements.location_phone_number.value;
        fax = match.elements.location_fax.value;
    } else {
        city = props.city;
        street = props.street;
        state = props.state;
        postcode = props.postcode;
        phone = props.phone;
        fax = props.fax;
    }

    const appointment = (street === '' || street === DEFAULT_EMPTY_ADDRESS) ? true : false;
    const encodedLocation = encodeURI(`${street} ${city} ${state}`);
    const directions = `https://www.google.com/maps/dir/?api=1&destination=${encodedLocation}`;
    const formattedPhone = formatPhoneNumber(phone);

    return (
        <div className="location-item">
            <div className="location">
                <Icon icon="location" />
                <div className="address">
                    <strong>{city}</strong>
                    <RichText content={{value: appointment ? '<p>by appointment only</p>' : displayStreet}}/>
                    <p>{city} {state} {postcode}</p>
                </div>
            </div>
            <div className="contact">
                <div className="phone">
                    <span>Telephone:</span>
                    <a href={`tel:${formattedPhone}`}>{phone}</a>
                </div>
                <div className="fax">
                    <span>Fax:</span>
                    {fax}
                </div>
            </div>
            <div className="directions">
                {!appointment &&
                    <a href={directions} target="_blank" rel="noopener noreferrer">Get Directions<Icon icon="arrow-right" /></a>
                }
            </div>
        </div>
    );
};

export default LocationItem;

LocationItem.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),
    city: PropTypes.string,
    street: PropTypes.string,
    state: PropTypes.string,
    postcode: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
};
