import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ContentIconTile from '~2-components/ContentIconTile/ContentIconTile';

const ContentIconTileKontent = (props) => {
    const tileID = props.system.id;

    const data = useStaticQuery(graphql`
        query allContentIconTiles {
            allKontentItemContentIconTile {
                nodes {
                    elements {
                        title {
                            value
                        }
                        blurb {
                            value
                        }
                        illustration_icon_list__illustration_icon_list {
                            value {
                                codename
                            }
                        }
                        link {
                            value {
                                ... on kontent_item_general_content {
                                    elements {
                                        page_slug {
                                            value
                                        }
                                    }
                                }
                            }
                        }
                    }
                    system {
                        id
                    }
                }
            }
        }
    `);
    const {tileLayout, step, numberedTiles, linkTxt} = props;

    const match = useMemo(() => (
        data.allKontentItemContentIconTile.nodes.find((n) => tileID === n.system.id)
    ), [ data, tileID]);

    if(!match.elements){ return; }

    const icon = match.elements.illustration_icon_list__illustration_icon_list.value[0] ? match.elements.illustration_icon_list__illustration_icon_list.value[0].codename : '';
    const title = match.elements.title ? match.elements.title.value : '';
    const blurb = match.elements.blurb ? match.elements.blurb.value : '';
    const linkUrl = match.elements.link.value[0] ? match.elements.link.value[0].elements.page_slug.value : null;


    return (
        <ContentIconTile
            icon={icon}
            title={title}
            blurb={blurb}
            tileLayout={tileLayout}
            step={step}
            numberedTiles={numberedTiles}
            linkUrl={linkUrl}
            linkTxt={linkTxt}
        />
    );

};

export default ContentIconTileKontent;

ContentIconTileKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.required
    }),
    tileLayout: PropTypes.string,
    step: PropTypes.string,
    numberedTiles: PropTypes.bool,
    linkTxt: PropTypes.string,
};
