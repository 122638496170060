import React from 'react';
import PropTypes from 'prop-types';
import IframeResizer from 'iframe-resizer-react';

const IframeForm = props => {
    const {
        title,
        imgUrl,
        imgAlt,
        children,
        cmsImage,
        embedURL,
        embedCode
    } = props;

    return (
        <div className="iframe-form page-section">
            <div className="container">
                <div className="form-content-wrapper">
                    <h4 className="form-title">{title}</h4>
                    <div className="form-text">
                        {children}
                    </div>
                    <div className="form-container">
                        {embedURL &&
                            <IframeResizer
                                autoResize
                                src={embedURL}
                                style={{ width: '1px', minWidth: '100%' }}
                            />
                        }
                        {embedCode &&
                            <div dangerouslySetInnerHTML={{ __html: embedCode}} />
                        }
                    </div>
                </div>
                <div className="image-container">
                    {cmsImage &&
                        cmsImage
                    }

                    {imgUrl &&
                        <img src={imgUrl} alt={imgAlt} />
                    }
                </div>
            </div>
        </div>
    );
};

export default IframeForm;

IframeForm.propTypes = {
    title: PropTypes.string,
    formId: PropTypes.string,
    imgUrl: PropTypes.string,
    imgAlt: PropTypes.string,
    children: PropTypes.node,
    cmsImage: PropTypes.node,
    embedURL: PropTypes.string,
    embedCode: PropTypes.string,
};
