import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import AccordionItem from '~2-components/Accordion/AccordionItem';

const AccordionItemKontent = (props) => {
    const { system } = props;
    const { id: itemId } = system;

    const allAccordionItems = useStaticQuery(graphql`
        query allAccordionItems {
            allKontentItemAccordionItem {
                nodes {
                    system {
                        id
                    }
                    elements {
                        title {
                            value
                        }
                        text {
                            value
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        allAccordionItems.allKontentItemAccordionItem.nodes.find((n) => itemId === n.system.id)
    ), [ allAccordionItems, itemId]);

    const {
        title,
        text,
    } = match.elements;

    return (
        <AccordionItem
            title={title.value}
            content={text.value}
        />
    );
};

export default AccordionItemKontent;

AccordionItemKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
};
