import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LocationItem from '~2-components/LocationItem/LocationItem';
import { useStaticQuery, graphql } from 'gatsby';

const LocationList = (props) => {
    let locations;

    if (props.system) {
        // data comes from CMS

        const locationListId = props.system.id;

        const allLocationLists = useStaticQuery(
            graphql`
                query allLocationLists {
                    allKontentItemLocationlist {
                        nodes {
                            system {
                                id
                            }
                            elements {
                                list {
                                    modular_content {
                                        system {
                                            id
                                            type
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
        `);

        const match = useMemo(() => (
            allLocationLists.allKontentItemLocationlist.nodes.find((n) => locationListId === n.system.id)
        ), [ allLocationLists, locationListId]);

        locations = match.elements.list.modular_content;
    } else {
        locations = props.locations;
    }

    return (
        <div className="location-list">
            {locations.map((item, i) => {
                return (
                    <LocationItem
                        key={i}
                        {...item}
                    />
                );
            })}
        </div>
    );
};

export default LocationList;

LocationList.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    locations: PropTypes.array,
};
