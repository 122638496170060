import React from 'react';
import PropTypes from 'prop-types';

const LinkList = (props) => {
    const title = props.title;

    return (
        <div className="linklist">
            {title &&
                <h3 className="linklist-title">{title}</h3>
            }

            <div className="linklist-items">
                {props.children}
            </div>
        </div>
    );
};

LinkList.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};

export default LinkList;
