import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const InlineLink = ({ content, link_id, type, url_slug }) => {
    let url;

    switch (type) {
        case 'homepage': {
            url = `/`;
            break;
        }

        default: {
            url = url_slug;
        }
    }

    return (
        <Link key={link_id} to={`/${url}`}>
            {content}
        </Link>
    );
};

export default InlineLink;

InlineLink.propTypes = {
    content: PropTypes.string.isRequired,
    linkId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    urlSlug: PropTypes.string.isRequired,
};
