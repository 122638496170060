/* eslint-disable @typescript-eslint/camelcase */
import { useStaticQuery, graphql } from 'gatsby';
import { pageNodeToFilterOption } from './FilterOptionsHelpers';
import {LinkedItemNode} from '~/@types/pages';


interface PracticeAreasNodeData {
    allKontentItemGeneralContent: LinkedItemNode;
}

const MultichoicePracticeAreaData = () => {
    const data: PracticeAreasNodeData = useStaticQuery(graphql`
        {
            allKontentItemGeneralContent {
                nodes {
                    elements {
                        title {
                            value
                        }
                    }
                    system {
                        type
                        id
                    }
                }
            }
        }
    `);

    const PracticeAreasNode = data.allKontentItemGeneralContent.nodes;
    return pageNodeToFilterOption(PracticeAreasNode);
};

export default MultichoicePracticeAreaData;
