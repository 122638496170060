import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import Button from '~1-core/buttons/Button';

const ContentImageTile = (props) => {
    let imgUrl, imgAlt, title, blurb, linkText, linkUrl;
    let description, id, image; // Used for Gatsby Image

    if (props.system) {
        // data comes from CMS
        const tileID = props.system.id;

        const data = useStaticQuery(graphql`
            query allContentImageTiles {
                allKontentItemContentImageTile {
                    nodes {
                        system {
                            id
                        }
                        elements {
                            blurb {
                                value
                            }
                            title {
                                value
                            }
                            image {
                                value {
                                    fluid(maxWidth: 588) {
                                        ...KontentAssetFluid_withWebp
                                    }
                                }
                            }
                            cta_link_url {
                                value {
                                    ... on kontent_item_general_content {
                                        elements {
                                            page_slug {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                            cta_link_text {
                                value
                            }
                        }
                    }
                }
            }
        `);

        const match = useMemo(() => (
            data.allKontentItemContentImageTile.nodes.find((n) => tileID === n.system.id)
        ), [ data, tileID]);

        //interface
        title = match.elements.title.value;
        blurb = match.elements.blurb.value;
        linkText = match.elements.cta_link_text.value;
        linkUrl = match.elements.cta_link_url.value.length > 0 ? match.elements.cta_link_url.value[0].elements.page_slug.value : '';
        const imageAttr = match.elements.image.value[0];

        //gatsby image
        description = imageAttr.description;
        id = imageAttr.name;
        image = imageAttr.fluid;
    } else {
        // data is hardcoded from /src/data
        imgUrl = props.imgUrl;
        imgAlt = props.imgAlt;
        title = props.title;
        blurb = props.blurb;
        linkText = props.linkText;
        linkUrl = props.linkUrl;
    }

    return (
        <div className="content-image-tile">
            <div className="tile-content">
                <div className="image-container">
                    {(props.system && image) &&
                        <GatsbyImage
                            key={id}
                            alt={description}
                            fluid={image}
                            style={{position: "absolute"}}
                        />
                    }
                    {!props.system &&
                        <img src={imgUrl} alt={imgAlt} />
                    }
                </div>
                <div className="text-container">
                    <h4 className="tile-title">{title}</h4>
                    <div className="blurb" dangerouslySetInnerHTML={{__html: blurb}} />
                    <Button
                        href={linkUrl}
                        skin='2'
                    >
                        {linkText}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ContentImageTile;

ContentImageTile.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    imgUrl: PropTypes.string,
    imgAlt: PropTypes.string,
    title: PropTypes.string,
    blurb: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
};

