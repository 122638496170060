import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ProfileTile from '~2-components/ProfileTile/ProfileTile';
import GatsbyImage from 'gatsby-image';

const ProfileTileKontent = (props) => {
    const { system } = props;
    const { id: tileId } = system;

    const tileData = useStaticQuery(graphql`
        query ProfileTiles {
            allKontentItemProfile {
                nodes {
                    system {
                        id
                    }
                    elements {
                        thumbnail_image {
                            value {
                                description
                                name
                                fluid(maxWidth: 152) {
                                    ...KontentAssetFluid_withWebp
                                }
                            }
                        }
                        page_url {
                            value
                        }
                        law_institute_specialist_accreditation {
                            value {
                                codename
                            }
                        }
                        last_name {
                            value
                        }
                        first_name {
                            value
                        }
                        position {
                            value
                        }
                        phone {
                            value
                        }
                        email {
                            value
                        }
                        linkedin {
                            value
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        tileData.allKontentItemProfile.nodes.find((n) => tileId === n.system.id)
    ), [ tileData, tileId]);

    const {law_institute_specialist_accreditation, first_name, last_name, position, phone, email, linkedin, page_url, thumbnail_image} = match.elements;

    const accredited = law_institute_specialist_accreditation.value.length > 0 ? law_institute_specialist_accreditation.value[0].codename : '';
    const cmsImage = thumbnail_image.value[0];
    const id = cmsImage && cmsImage.name;
    const description = cmsImage && cmsImage.description;
    const image = cmsImage && cmsImage.fluid;

    return (
        <ProfileTile
            key={`${first_name.value}-profile-tile`}
            accredited={accredited}
            firstName={first_name.value}
            lastName={last_name.value}
            position={position.value}
            phone={phone.value}
            email={email.value}
            linkedIn={linkedin.value}
            urlSlug={page_url.value}
            cmsImage={cmsImage &&
            <GatsbyImage
                key={id}
                alt={description}
                fluid={image}
            />
        }
        />
    );
};

export default ProfileTileKontent;

ProfileTileKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
};
